import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import SEO from "../components/seo";
import PageLayout from '../components/page-layout';
import StlList from '../components/stls';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title={"3D Printed Parts"} mdxType="SEO" />

    <h1>{`3D Printed Parts`}</h1>
    <p>{`Every part can be printed on a small 15x15cm build plate is properly placed.`}<br parentName="p"></br>{`
`}{`0.3mm layer height is enough with 20% infill and 1.2mm wall width.`}<br parentName="p"></br>{`
`}{`Everything should fit together after supports are removed. `}</p>
    <StlList mdxType="StlList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      